import _ from "lodash";
import React, {
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { fetchServerData } from "src/api";
import { UNISAT_NETWORK, UNISAT_NETWORK_ALERT } from "src/config";
import useGlobalContext from "src/hooks/useGlobalContext";
import useWACContext from "src/hooks/useWACContext";
import { ClaimPageStep } from "src/types/claimPageStep";
import { WALLET_METHOD } from "src/types/contexts";
import { OrderData, OrderDetail } from "src/types/inscribe";

interface SoulBoundContextProps {
  order: OrderDetail | undefined;
  orders: OrderData[];
  setOrder: (value: OrderDetail | undefined) => void;
  setOrders: (value: OrderData[]) => void;
  checkOrderStatus: (id: string) => Promise<void>;
}

export const SoulBoundContext = createContext<SoulBoundContextProps>(
  {} as SoulBoundContextProps
);

interface SoulBoundProviderProps {
  children: ReactNode;
}

export const SoulBoundProvider: React.FC<SoulBoundProviderProps> = ({
  children,
}) => {
  const timerRef = useRef<any>(null);
  const { setClaimStep, setIsLoading } = useGlobalContext();
  const { walletMethod, ordinalAccount, switchNetwork, disconnectWallet } =
    useWACContext();
  const [order, setOrder] = useState<OrderDetail>();
  const [orders, setOrders] = useState<OrderData[]>([]);

  useEffect(() => {
    if (ordinalAccount) {
      setClaimStep(ClaimPageStep.CLAIM);
    }
    setIsLoading(false);
  }, [ordinalAccount]);

  const RunTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (!timerRef.current && order?.id) {
      timerRef.current = setTimeout(() => {
        checkOrderStatus(order.id);
        RunTimer();
      }, 60000);
    }
  };

  const StopTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  useEffect(() => {
    const fetchOrdersData = async () => {
      try {
        if (walletMethod === WALLET_METHOD.UNISAT) {
          const _network = await window.unisat.getChain();

          if (_network.network !== UNISAT_NETWORK) {
            toast.error(UNISAT_NETWORK_ALERT);
            setClaimStep(ClaimPageStep.CONNECT);
            disconnectWallet();
            return;
          }
        }

        const { data, status } = await fetchServerData({
          url: `/soulbound/search/${ordinalAccount}`,
        });

        console.log(data, status);

        if (status === 200) {
          setOrders(
            data.orders.map((_order: any) => {
              return {
                id: _order.order_id,
                status: _order.status,
                inscription: _order.inscription,
                createAt: _order.createdAt,
              };
            })
          );
        }
      } catch (err) {
        setIsLoading(false);
      }
    };

    if (ordinalAccount) {
      console.log(ordinalAccount);
      fetchOrdersData();
    } else {
      setIsLoading(false);
    }
  }, [ordinalAccount]);

  const checkOrderStatus = async (orderId: string) => {
    try {
      const { data, status } = await fetchServerData({
        url: `/soulbound/order/${orderId}`,
      });

      if (status === 200 && data.order && data.orders) {
        // const _storedOrders: OrderData[] = storedOrders
        //   ? JSON.parse(storedOrders)
        //   : [];
        // const _orders = _storedOrders.map((_order) => {
        //   if (_order.id === data.id) return { ..._order, status: data.state };
        //   return _order;
        // });
        // const findOrder = _storedOrders.filter(
        //   (_order) => _order.id === data.id
        // );
        // if (_.isEmpty(findOrder))
        //   _orders.push({
        //     id: data.id,
        //     status: data.state,
        //     createAt: data?.charge.created_at,
        //   });
        // setOrders(_orders);
        // setStoredOrders(JSON.stringify(_orders));
        console.log(data);
        setOrders(
          data.orders.map((_order: any) => {
            return {
              id: _order.order_id,
              status: _order.status,
              createAt: _order.createdAt,
            };
          })
        );

        setOrder({
          id: data.order.id,
          fee: data.order.fee,
          totalFee: data.order.charge.amount,
          paymentAddress: data.order?.charge?.address,
          parent: data.order?.parent?.inscriptionId,
          depositAddress: data.order?.parent?.depositAddress,
          returnAddress: data.order?.parent?.returnAddress,
          receiveAddress: data.order?.receiveAddress,
          commitTx: data?.order?.files[0]?.tx?.commit,
          revealTx: data?.order?.files[0]?.tx?.reveal,
          status: data.order?.state,
        });
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("order ===>", order);
    if (!_.isEmpty(order)) {
      RunTimer();
    } else {
      StopTimer();
    }
  }, [order]);

  return (
    <SoulBoundContext.Provider
      value={{ order, orders, setOrder, setOrders, checkOrderStatus }}
    >
      {children}
    </SoulBoundContext.Provider>
  );
};

export default SoulBoundProvider;
