import { useEffect, useState } from "react";
import bigInt from "big-integer";
import _ from "lodash";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import { SelectWallet } from "../../components/SelectWallet";
import Container from "../../components/Container";
import styles from "./index.module.scss";
import backgroundImage from "../../assets/img/linedBackground.png";
import {
  convertToIntegerWithComma,
  getReducedText,
  replaceMiddleWithDots,
} from "src/utils";
import { MEMPOOL_URL } from "src/config";
import useWACContext from "src/hooks/useWACContext";
import useGlobalContext from "src/hooks/useGlobalContext";
import { ClaimPageStep } from "src/types/claimPageStep";
import { API_STYLE, MODAL_STATUS, MODAL_STATUS_TYPE } from "src/types";
import TxModal from "src/components/TxModal";
import InputBox from "src/components/InputBox";
import { fetchServerData } from "src/api";
import useSoulboundContext from "src/hooks/useSoulboundContext";
import {
  CopyLabel,
  CopyLabelType,
  OrderData,
  OrderStatus,
} from "src/types/inscribe";
import UtxoList from "src/components/UtxoList";
import CopyIcon from "src/assets/img/copyIcon";
import CInput from "src/components/CInput";
import { useNavigate } from "react-router-dom";

const SoulBound: React.FC<{ child?: boolean }> = ({ child }) => {
  const navigate = useNavigate();
  const { claimStep, setIsLoading, setClaimStep } = useGlobalContext();
  const { isConnected, ordinalAccount, disconnectWallet } = useWACContext();
  const [txId, setTxId] = useState("");
  const [category, setCategory] = useState("");
  const [level, setLevel] = useState("");
  const [content, setContent] = useState({});
  const [feeRate, setFeeRate] = useState("0");
  const { order, orders, setOrder, checkOrderStatus } = useSoulboundContext();
  const [inscriptions, setInscriptions] = useState<OrderData[]>([]);

  const [receverAddress, setReceiverAddress] = useState("");
  const [parentId, setParentId] = useState("");

  const [orderModalOpen, setOrderModalOpen] = useState<MODAL_STATUS_TYPE>(
    MODAL_STATUS.CLOSE
  );

  const [selectedCheck, setSelectedCheck] = useState<CopyLabelType>(
    CopyLabel.DEFAULT
  );

  // const fetchAllSB = async () => {
  //   try {
  //     const { status, data } = await fetchServerData({
  //       url: "/check-wallet",
  //       method: API_STYLE.POST,
  //       param: {
  //         ordinal_address: ordinalAccount,
  //         company_name,
  //       },
  //     });
  //   } catch (error) {}
  // };

  useEffect(() => {
    if (child && orders) {
      // setInscriptions(orders.filter((_order) => _order?.inscription));
    }
  }, [child, orders]);

  useEffect(() => {
    if (!_.isEmpty(order)) {
      if (order?.status === OrderStatus.COMPLETED && order?.revealTx) {
        setOrderModalOpen(MODAL_STATUS.SUCCESS);
        setTxId(order?.revealTx);
      } else setOrderModalOpen(MODAL_STATUS.PROGRESS);
    }
  }, [order]);

  const handleOpenClaimModal = async () => {
    try {
      const _level = bigInt(level.replaceAll(",", ""));
      if (_level.leq(bigInt(0))) return toast.error("Invalid Level Value");
      if (!category) return toast.error("Invalid Category Value");
      if (!receverAddress) return toast.error("Invalid Receiver Address");

      setOrderModalOpen(MODAL_STATUS.DEFAULT);

      // const { status, data } = await fetchMempoolAPI({
      //   url: "/v1/fees/recommended",
      // });
      // console.log(status, data);
      // if (status === 200) {
      //   setFeeRate(data?.halfHourFee);
      // } else {
      //   setFeeRate("0");
      // }

      setFeeRate("265");
    } catch (error) {
      console.error("Error fetching gas fees:", error);
      // Handle the error appropriately.
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOrder(undefined);
    setOrderModalOpen(MODAL_STATUS.CLOSE);
  };

  const onChangeCategory = (value: string) => {
    setCategory(value);
  };

  const onChangeLevel = (value: string) => {
    setLevel(convertToIntegerWithComma(value));
  };
  //https://pqina.nl/blog/convert-a-file-to-a-base64-string-with-javascript/
  const handleConfirmInscribe = async () => {
    try {
      const _level = bigInt(level.replaceAll(",", ""));
      if (_level.leq(bigInt(0))) return toast.error("Invalid Level Value");
      if (!category) return toast.error("Invalid Category Value");
      if (!receverAddress) return toast.error("Invalid Receiver Address");
      if (Number(feeRate) <= 0) return toast.error("Fetching fee rate failed");

      setIsLoading(true);

      const text = JSON.stringify(content);

      // const { status, data } = await fetchOrdinalbotAPI({
      //   url: `/order`,
      //   method: API_STYLE.POST,
      //   param: {
      //     batchMode: "separate-outputs",
      //     files: [
      //       {
      //         dataURL: `data:text/plain;charset=utf-8;base64,${stringToBase64(
      //           text
      //         )}`,
      //         size: getStringByteCount(text),
      //         name: `2.txt`,
      //         type: "plain/text",
      //       },
      //     ],
      //     lowPostage: true,
      //     receiveAddress: receverAddress,
      //     fee: feeRate,
      //     rareSats: "random",
      //     parent: {
      //       returnAddress: receverAddress,
      //       inscriptionId: parentId,
      //     },
      //   },
      // });

      const { status, data } = await fetchServerData({
        url: "/soulbound/create",
        method: API_STYLE.POST,
        param: {
          wallet_address: ordinalAccount,
          receive_address: receverAddress,
          parent_id: child ? parentId : null,
          content: text,
        },
      });
      console.log(status, data);
      if (status === 200) {
        await checkOrderStatus(data.id);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let tp = {};
    if (ordinalAccount) tp = { ...tp, op: "soul", wallet: ordinalAccount };
    const _level = bigInt(level.replaceAll(",", ""));
    if (category) tp = { ...tp, category };
    if (_level.gt(bigInt(0))) tp = { ...tp, level };
    setContent(tp);
  }, [level, category, ordinalAccount]);

  return (
    <>
      <Container bgImage={backgroundImage}>
        <div className={styles.container}>
          <div>
            <div className="w-sm py-4 px-24 mt-24  rounded-sm">
              <div className="pb-4">
                <h1 className="text-white text-5xl text-shadow">
                  {`Inscribe ${child ? "Child" : "Parent"} SoulBound`}
                </h1>
              </div>

              <div>
                {claimStep === ClaimPageStep.DEFAULT ? (
                  <div className={styles.connect_wrapper}>
                    <button
                      onClick={() => {
                        if (!isConnected) {
                          setClaimStep(ClaimPageStep.CONNECT);
                        }
                      }}
                      className={styles.connectWallet}
                    >
                      Connect
                    </button>

                    <p className={styles.connect_label}>
                      Connect wallet to inscribe souldbound
                    </p>
                  </div>
                ) : claimStep === ClaimPageStep.CONNECT ? (
                  <SelectWallet isOpen={claimStep === ClaimPageStep.CONNECT} />
                ) : (
                  <div className={styles.content}>
                    <div className={styles.connected_wallet_wrapper}>
                      <div className={styles.connected_address}>
                        <span className={styles.connected_address__label}>
                          Connected wallet:{" "}
                        </span>
                        <div
                          className={styles.connected_address__address}
                          onClick={() =>
                            window.open(
                              `${MEMPOOL_URL}/address/${ordinalAccount}`,
                              "_blank"
                            )
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            cursor: "pointer",
                          }}
                        >
                          <span className="text-white font-sans font-bold text-xl text-shadow signika">
                            {replaceMiddleWithDots(
                              ordinalAccount?.toString() ?? ""
                            )}
                          </span>
                          <div style={{ cursor: "pointer" }}>
                            <svg
                              className="h-8 w-8 text-white"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                              <line x1="10" y1="14" x2="20" y2="4" />
                              <polyline points="15 4 20 4 20 9" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <button
                        className={styles.connectWallet}
                        onClick={() => {
                          disconnectWallet();
                        }}
                      >
                        Disconnect
                      </button>
                    </div>

                    <div className={styles.formWrapper}>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrapper}>
                          <CInput
                            fill
                            placeholder="Category"
                            onChange={onChangeCategory}
                            value={category}
                          />
                          <CInput
                            fill
                            placeholder="Level"
                            onChange={onChangeLevel}
                            value={level}
                          />
                        </div>
                      </div>
                      {child && (
                        <div className={styles.inputContainer}>
                          {inscriptions?.length > 0 ? (
                            <div className={styles.parentsList}>
                              {inscriptions.map((_order) => (
                                <div
                                  className={styles.parentItem}
                                  onClick={() =>
                                    setParentId(_order.inscription!)
                                  }
                                >
                                  {getReducedText(_order.inscription, 10, 10)}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className={styles.directTo}>
                              You don't have parent soulbound.{" "}
                              <span
                                onClick={() => navigate("/souldbound/parent")}
                              >
                                Create parent souldbound First!
                              </span>
                            </div>
                          )}
                          <div className={styles.inputWrapper}>
                            <CInput
                              fill
                              disabled
                              placeholder="Parent Inscription ID"
                              onChange={setParentId}
                              value={parentId}
                            />
                          </div>
                        </div>
                      )}
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrapper}>
                          <CInput
                            fill
                            placeholder="Receiver Address"
                            onChange={setReceiverAddress}
                            value={receverAddress}
                          />

                          <button
                            className={styles.connectWallet}
                            onClick={handleOpenClaimModal}
                          >
                            Inscribe
                          </button>
                        </div>
                      </div>
                    </div>

                    <InputBox value={content} />
                    <UtxoList title="Order List" orders={orders} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <TxModal
          status={orderModalOpen}
          txId={txId}
          confirmContent={
            <div className={styles.successContent}>
              <div className={styles.ognlInfo}>
                Great, You are inscribing SoulBound
              </div>
            </div>
          }
          progressContent={
            order?.status === OrderStatus.DEFAULT ? (
              <div className={styles.progressContent}>
                <div className={styles.status}>Waiting for payment...</div>
                <div>
                  <div className={styles.label}>
                    Pay BTC Here:{" "}
                    <span
                      className={styles.hover}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          order?.paymentAddress ?? ""
                        );
                        setSelectedCheck(CopyLabel.PAYMENT);
                      }}
                    >
                      {order?.paymentAddress}
                    </span>
                    <CopyIcon
                      id={CopyLabel.PAYMENT}
                      selected={selectedCheck}
                      setSelected={setSelectedCheck}
                      copyValue={order?.paymentAddress ?? ""}
                    />
                  </div>
                  <div className={styles.label}>
                    Amount: <span>{order?.totalFee}</span>
                  </div>
                </div>
              </div>
            ) : order?.status === OrderStatus.PARENT ? (
              <div className={styles.progressContent}>
                <div className={styles.status}>
                  Waiting for Parent Transfer...
                </div>
                <div>
                  <div
                    className={styles.label}
                    style={{ flexDirection: "column" }}
                  >
                    Inscription ID:{" "}
                    <div style={{ display: "flex", gap: 10 }}>
                      <span
                        className={styles.hover}
                        onClick={() => {
                          navigator.clipboard.writeText(order?.parent ?? "");
                          setSelectedCheck(CopyLabel.INSCRIPTION);
                        }}
                      >
                        {order?.parent}
                      </span>
                      <CopyIcon
                        id={CopyLabel.INSCRIPTION}
                        selected={selectedCheck}
                        copyValue={order?.parent ?? ""}
                        setSelected={setSelectedCheck}
                      />
                    </div>
                  </div>
                  <div
                    className={styles.label}
                    style={{ flexDirection: "column" }}
                  >
                    Deposit Address:{" "}
                    <div style={{ display: "flex", gap: 10 }}>
                      <span
                        className={styles.hover}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            order?.depositAddress ?? ""
                          );
                          setSelectedCheck(CopyLabel.DEPOSIT);
                        }}
                      >
                        {order?.depositAddress}
                      </span>
                      <CopyIcon
                        id={CopyLabel.DEPOSIT}
                        selected={selectedCheck}
                        copyValue={order?.depositAddress ?? ""}
                        setSelected={setSelectedCheck}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : order?.status === OrderStatus.QUEUE ||
              order?.status === OrderStatus.MEMPOOL ? (
              <div className={styles.progressContent}>
                <div>Waiting for order completion</div>
              </div>
            ) : order?.status === OrderStatus.PAYMENT_CONFIRMATION ? (
              <div className={styles.progressContent}>
                <div className={styles.status}>Confirming Payment...</div>
                <div>
                  <div className={styles.label}>
                    Pay BTC Here:{" "}
                    <span
                      className={styles.hover}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          order?.paymentAddress ?? ""
                        );
                        setSelectedCheck(CopyLabel.PAYMENT);
                      }}
                    >
                      {order?.paymentAddress}
                    </span>
                    <CopyIcon
                      id={CopyLabel.PAYMENT}
                      selected={selectedCheck}
                      setSelected={setSelectedCheck}
                      copyValue={order?.paymentAddress ?? ""}
                    />
                  </div>
                  <div className={styles.label}>
                    Amount: <span>{order?.totalFee}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.progressContent}>
                <div className={styles.status}>
                  Waiting for order completion...
                </div>
                {order?.commitTx && (
                  <div>
                    <div className={styles.label}>
                      Commit:{" "}
                      <span
                        className={styles.hover}
                        onClick={() => {
                          navigator.clipboard.writeText(order?.commitTx ?? "");
                          setSelectedCheck(CopyLabel.COMMIT);
                        }}
                      >
                        {order?.commitTx}
                      </span>
                      <CopyIcon
                        id={CopyLabel.COMMIT}
                        selected={selectedCheck}
                        setSelected={setSelectedCheck}
                        copyValue={order?.commitTx ?? ""}
                      />
                    </div>
                  </div>
                )}
                <div>
                  <div className={styles.label}>
                    REVEAL:{" "}
                    <span
                      className={styles.hover}
                      onClick={() => {
                        navigator.clipboard.writeText(order?.revealTx ?? "");
                        setSelectedCheck(CopyLabel.REVEAL);
                      }}
                    >
                      {order?.revealTx}
                    </span>
                    <CopyIcon
                      id={CopyLabel.REVEAL}
                      selected={selectedCheck}
                      setSelected={setSelectedCheck}
                      copyValue={order?.revealTx ?? ""}
                    />
                  </div>
                </div>
              </div>
            )
          }
          successText={`Congratulations! You've successfully inscribed Soulbound`}
          onConfirm={handleConfirmInscribe}
          onClose={handleCloseModal}
        />
      </Container>
      <Footer />
    </>
  );
};

export default SoulBound;
