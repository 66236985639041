import React from "react";
import _ from "lodash";
import styles from "./index.module.scss";
import { convertDisplayDateTime, getReducedText } from "src/utils";
import { OrderData } from "src/types/inscribe";
import useSoulboundContext from "src/hooks/useSoulboundContext";

const OrderTable: React.FC<{ orders: OrderData[] }> = ({ orders }) => {
  const { checkOrderStatus } = useSoulboundContext();

  const handleViewOrder = async (orderId: string) => {
    await checkOrderStatus(orderId);
  };

  return (
    <>
      <table className={styles.historyTable}>
        <thead>
          <tr className={styles.historyTableHeaderRow}>
            <th className={styles.historyTableHeaderCell}>ID</th>
            <th className={styles.historyTableHeaderCell}>STATUS</th>
            <th className={styles.historyTableHeaderCell}>DATE</th>
          </tr>
        </thead>
        <tbody>
          {orders?.length > 0 &&
            orders.map((_order, idx) => {
              return (
                <tr key={idx} className={styles.historyTableRow}>
                  <td className={`${styles.historyTableCell}`}>
                    <div
                      className={`${styles.viewDetail}`}
                      onClick={() => handleViewOrder(_order.id)}
                    >
                      {getReducedText(_order.id)}
                    </div>
                  </td>
                  <td className={`${styles.historyTableCell}`}>
                    {_order.status}
                  </td>
                  <td className={`${styles.historyTableCell}`}>
                    {convertDisplayDateTime(new Date(_order.createAt))}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default OrderTable;
