import { Route, Routes } from "react-router-dom";
import ClaimRune from "src/pages/ClaimRune";
import NotFound from "src/components/NotFound";
import SplitRune from "src/pages/SplitRune";
import ClaimProvider from "src/contexts/ClaimContext";
import UtxoProvider from "src/contexts/UtxoContext";
import SoulBound from "src/pages/SoulBound";
import SoulBoundProvider from "src/contexts/SoulBoundContext";

function AppRouter() {
  return (
    <div className="d-flex flex-column h-100 primary-bg">
      <div className="app-bg flex-grow-1">
        <Routes>
          <Route
            path=""
            element={
              <ClaimProvider>
                <ClaimRune />
              </ClaimProvider>
            }
          />
          <Route
            path="/split-runes"
            element={
              <UtxoProvider>
                <SplitRune />
              </UtxoProvider>
            }
          />
          <Route
            path="/soulbound"
            element={
              <SoulBoundProvider>
                <SoulBound child />
              </SoulBoundProvider>
            }
          />
          <Route
            path="/soulbound/parent"
            element={
              <SoulBoundProvider>
                <SoulBound />
              </SoulBoundProvider>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default AppRouter;
