import { useContext } from "react";
import { WACContext } from "src/contexts/WACContext";

const useWACContext = () => {
  const context = useContext(WACContext);

  if (!context) {
    throw new Error("useWACContext must be used within a ThemecontextProvider");
  }

  const {
    walletMethod,
    isConnected,
    btcBalance,
    paymentAccount,
    ordinalAccount,
    paymentPubkey,
    network,
    connectWallet,
    disconnectWallet,
    switchNetwork,
  } = context;

  return {
    walletMethod,
    isConnected,
    paymentAccount,
    ordinalAccount,
    paymentPubkey,
    btcBalance,
    network,
    connectWallet,
    disconnectWallet,
    switchNetwork,
  };
};
export default useWACContext;
