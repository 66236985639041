import { useContext } from "react";
import { ClaimContext } from "src/contexts/ClaimContext";

const useClaimContext = () => {
  const context = useContext(ClaimContext);

  if (!context) {
    throw new Error(
      "useWalletContext must be used within a ThemecontextProvider"
    );
  }

  const {
    claims,
    newClaim,
    txStatus,
    lockedAmount,
    claimStatus,
    claimableAmount,
    claimedAmount,
    claimingAmount,
    setClaims,
    setNewClaim,
    setTxStatus,
    setClaimStatus,
  } = context;

  return {
    claims,
    newClaim,
    txStatus,
    claimStatus,
    lockedAmount,
    claimableAmount,
    claimedAmount,
    claimingAmount,
    setClaims,
    setNewClaim,
    setTxStatus,
    setClaimStatus,
  };
};
export default useClaimContext;
