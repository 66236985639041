import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "./routers/index";
import Layout from "./components/Layout";
import GlobalProvider from "./contexts/GlobalContext";
import WACProvider from "./contexts/WACContext";

function App() {
  return (
    <div className="h-100">
      <BrowserRouter>
        <GlobalProvider>
          <WACProvider>
            <Layout>
              <AppRouter />
            </Layout>
          </WACProvider>
        </GlobalProvider>
      </BrowserRouter>
      <ToastContainer autoClose={2000} position="top-right" />
    </div>
  );
}

export default App;
