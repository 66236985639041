import _ from "lodash";
import BigNumber from "bignumber.js";
import { Base64 } from "js-base64";

const DEFAULT_PRECISION = 0;

const defaultFormatSettings = {
  // string to prepend
  prefix: "",
  // decimal separator
  decimalSeparator: ".",
  // grouping separator of the integer part
  groupSeparator: ",",
  // primary grouping size of the integer part
  groupSize: 3,
  // secondary grouping size of the integer part
  secondaryGroupSize: 0,
  // grouping separator of the fraction part
  fractionGroupSeparator: " ",
  // grouping size of the fraction part
  fractionGroupSize: 0,
  // string to append
  suffix: "",
};

export type UTXO = {
  txid: string;
  vout: number;
  status: {
    confirmed: boolean;
    block_height?: number;
    block_hash?: string;
    block_time?: number;
  };
  value: number;
};

export function replaceMiddleWithDots(
  walletAddress: string | undefined,
  firstLength = 6,
  lastLength = 8
) {
  if (!walletAddress) return "";
  const firstPart = walletAddress.slice(0, firstLength);
  const lastPart = walletAddress.slice(walletAddress.length - lastLength);

  const hiddenMiddle = "...";

  return firstPart + hiddenMiddle + lastPart;
}

export const removeCommaFromString = (str: string) => str.replaceAll(",", "");

export const getReducedText = (
  str: string | undefined,
  startLen = 10,
  endLen = 10
) => {
  if (_.isEmpty(str)) return "";
  const value = removeCommaFromString(str!);
  return `${value?.substring(0, startLen)}...${value?.substring(
    value?.length - endLen
  )}`;
};

export const convertDisplayDateTime = (date: any) => {
  // Parse the datetime string with timezone awareness
  const datetimeObj = new Date(date);

  // Convert to the desired format (without seconds and timezone)
  const formattedDatetime = datetimeObj.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  return formattedDatetime;
};

export const numberWithCommas = (num: number | string) => {
  if (!num) return "0";
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertToIntegerWithComma = (value: any) => {
  let newValue = value;
  let str = value
    .toString()
    .replaceAll(",", "")
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  if (
    removeCommaFromString(value).toString()[0] === "0" &&
    removeCommaFromString(value).toString().length > 0
  )
    str = str.substring(1);
  if (/^\d*$/.test(removeCommaFromString(value))) newValue = str;
  else newValue = str.slice(0, value.length - 1);
  return newValue;
};

export function getStringByteCount(str: string) {
  let totalLength = 0;
  let charCode;
  for (let i = 0; i < str.length; i++) {
    charCode = str.charCodeAt(i);
    if (charCode < 0x007f) {
      totalLength++;
    } else if (0x0080 <= charCode && charCode <= 0x07ff) {
      totalLength += 2;
    } else if (0x0800 <= charCode && charCode <= 0xffff) {
      totalLength += 3;
    } else {
      totalLength += 4;
    }
  }
  return totalLength;
}

export function stringToBase64(stringToEncode: string) {
  // btoa only support ascii, use js-base64 instead
  return Base64.encode(stringToEncode);
}

export function forceNumeric(nr: any) {
  return nr
    .replace(",", ".")
    .replace(/[^0-9.]/g, "")
    .replace(/((\d+)?\.(\d+)?)\.(.*)/g, "$1");
}

export const isValidNumber = (
  text: any,
  precision = DEFAULT_PRECISION,
  isInteger = false
) => {
  const normalizedText = text.replace(/,/, ".");

  const integerNumberRegexp = /^\d*$/gi;

  if (isInteger) {
    return integerNumberRegexp.test(text);
  }

  const decimalNumberRegexp = new RegExp(`^\\d+\\.\\d{0,${precision}}$`, "gi");

  return (
    integerNumberRegexp.test(normalizedText) ||
    decimalNumberRegexp.test(normalizedText)
  );
};

function removeZerosFromEnd(num: any) {
  const numStr = num.toString();
  const integerPart = numStr.split(".")[0];
  const decimalPart = numStr.split(".")[1];

  if (!decimalPart) return numStr;

  let i = decimalPart.length - 1;

  for (; i >= 0; i--) {
    if (decimalPart[i] !== "0") break;
  }

  if (i < 0) return integerPart;

  return `${integerPart}.${decimalPart.slice(0, i + 1)}`;
}

export const number_format = (
  number: any,
  {
    precision = DEFAULT_PRECISION,
    short = false,
    noCommas = false,
    removeZeroEnd = false,
    roundingMode = BigNumber.ROUND_DOWN,
    formatSettings = {},
    returnNumber = false,
  } = {}
) => {
  const BGNumber = new BigNumber(number || 0);

  let formattedNumber = BGNumber.toFormat(precision, roundingMode, {
    ...defaultFormatSettings,
    ...formatSettings,
  });

  if (short && !returnNumber) {
    formattedNumber = shortNumberFormat(BGNumber.toString(), precision);
  }

  if (noCommas || returnNumber) {
    formattedNumber = BGNumber.toFormat(precision, roundingMode, {
      ...defaultFormatSettings,
      groupSeparator: "",
      ...formatSettings,
    });
  }

  const result = removeZeroEnd
    ? removeZerosFromEnd(formattedNumber)
    : formattedNumber;

  return returnNumber ? Number.parseFloat(result) : result;
};

export const bigNumberPrefixes = [
  { value: 1, symbol: "" },
  { value: 1e3, symbol: "K" },
  { value: 1e6, symbol: "M" },
  { value: 1e9, symbol: "G" },
  { value: 1e12, symbol: "T" },
  { value: 1e15, symbol: "P" },
  { value: 1e18, symbol: "E" },
  { value: 1e21, symbol: "Z" },
  { value: 1e24, symbol: "Y" },
];

function shortNumberFormat(num: any, digits: any) {
  const numToCheck = Math.abs(num);

  for (let i = bigNumberPrefixes.length - 1; i >= 0; i -= 1) {
    if (numToCheck >= bigNumberPrefixes[i].value) {
      const newNumber = (num / bigNumberPrefixes[i].value).toFixed(digits);

      return `${newNumber}${bigNumberPrefixes[i].symbol}`;
    }
  }

  return "0";
}
