import Footer from "../Footer";
import Container from "../Container";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/img/linedBackground.png";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container bgImage={backgroundImage}>
        <div className={styles.container}>
          <div className={styles.not_found}>Page Not Found | 404</div>
          <button
            onClick={() => {
              navigate("/");
            }}
            className={styles.go_home}
          >
            GO Home
          </button>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default NotFound;
