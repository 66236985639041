import React from "react";
import clx from "classnames";
import styles from "./index.module.scss";

const InputBox: React.FC<{ value: Record<string, any> }> = ({ value }) => {
  return (
    <div className={styles.history_wrapper}>
      <div className={styles.history_header}>
        <span>Soul Bound Content</span>
      </div>
      <div className={clx(styles.history_content)}>
        <textarea
          className={styles.textarea}
          disabled
          value={JSON.stringify(value)}
        />
      </div>
    </div>
  );
};

export default InputBox;
