export const ClaimPageStep = {
  DEFAULT: 0,
  CONNECT: 1,
  CLAIM: 2,
  TRANSFER: 3,
  SUCCESS: 4,
} as const;

export type ClaimPageStepType =
  (typeof ClaimPageStep)[keyof typeof ClaimPageStep];
