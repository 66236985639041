import React, { createContext, useState, ReactNode } from "react";
import { MODAL_DATA_TYPE } from "src/types";
import { ClaimPageStep, ClaimPageStepType } from "src/types/claimPageStep";

interface GlobalContextProps {
  isLoading: boolean;
  claimStep: ClaimPageStepType;
  modals: Record<string, MODAL_DATA_TYPE>;
  setModals: (val: any) => void;
  closeModal: (val: any) => void;
  setIsLoading: (val: boolean) => void;
  setClaimStep: (value: ClaimPageStepType) => void;
  openModal: (modalId: string, params?: any) => void;
}

export const GlobalContext = createContext<GlobalContextProps>(
  {} as GlobalContextProps
);

interface GlobalProviderProps {
  children: ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modals, setModals] = useState<Record<string, MODAL_DATA_TYPE>>({});
  const [claimStep, setClaimStep] = useState<ClaimPageStepType>(
    ClaimPageStep.DEFAULT
  );

  const openModal = (modalId: string, params?: any) => {
    setModals({ ...modals, [modalId]: { opened: true, params } });
  };

  const closeModal = (modalId: string) => {
    setModals({ ...modals, [modalId]: { opened: false } });
  };

  return (
    <GlobalContext.Provider
      value={{
        modals,
        isLoading,
        claimStep,
        setModals,
        openModal,
        closeModal,
        setIsLoading,
        setClaimStep,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
