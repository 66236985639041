import { BitcoinNetworkType } from "sats-connect";
import { BTC_NETWORK } from "./types/contexts";

const testVersion = true;

//export const SERVER_URL = "http://localhost:5000";
export const SERVER_URL = "https://claim-api.ordict.com";

export const MEMPOOL_URL = testVersion
  ? "https://mempool.space/testnet"
  : "https://mempool.space";

export const MEMPOOL_API_URL = testVersion
  ? "https://mempool.space/api"
  : "https://mempool.space/api";

export const XVERSE_NETWORK = testVersion
  ? BitcoinNetworkType.Testnet
  : BitcoinNetworkType.Mainnet;

export const UNISAT_NETWORK = testVersion
  ? BTC_NETWORK.TESTNET
  : BTC_NETWORK.LIVENET;

export const UNISAT_NETWORK_ALERT = testVersion
  ? "Please connect testnet."
  : "Please connect livenet.";

export const ORDINALBOT_URL = testVersion
  ? "https://testnet-api.ordinalsbot.com"
  : "https://api.ordinalsbot.com";
