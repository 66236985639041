import React, {
  useRef,
  useState,
  ReactNode,
  useEffect,
  createContext,
} from "react";
import { toast } from "react-toastify";
import { fetchServerData } from "src/api";
import { WALLET_METHOD } from "src/types/contexts";
import useWACContext from "src/hooks/useWACContext";
import useGlobalContext from "src/hooks/useGlobalContext";
import { Claim, ClaimStatus, ClaimStatusType, TxStatus } from "src/types/claim";
import { ClaimPageStep } from "src/types/claimPageStep";
import { UNISAT_NETWORK, UNISAT_NETWORK_ALERT } from "src/config";
import { API_STYLE } from "src/types";
import bigInt from "big-integer";
import { useParams } from "react-router-dom";

interface ClaimContextProps {
  claims: Claim[];
  newClaim: Claim | undefined;
  lockedAmount: string;
  claimStatus: ClaimStatusType;
  claimableAmount: string;
  claimingAmount: string;
  claimedAmount: string;
  txStatus: number;
  setClaims: (value: Claim[]) => void;
  setNewClaim: (value: Claim | undefined) => void;
  setTxStatus: (value: number) => void;
  setClaimStatus: (value: ClaimStatusType) => void;
}

export const ClaimContext = createContext<ClaimContextProps>(
  {} as ClaimContextProps
);

interface ClaimProviderProps {
  children: ReactNode;
}

export const ClaimProvider: React.FC<ClaimProviderProps> = ({ children }) => {
  const timerRef = useRef<any>(null);
  // const { company: company_name } = useParams();
  const company_name = "ordict";
  const [claimStatus, setClaimStatus] = useState<ClaimStatusType>(
    ClaimStatus.DEFAULT
  );
  const [txStatus, setTxStatus] = useState<number>(TxStatus.INITIATE);
  const [claims, setClaims] = useState<Claim[]>([]);
  const [newClaim, setNewClaim] = useState<Claim | undefined>();
  const [claimableAmount, setClaimableAmount] = useState("0");
  const [claimingAmount, setClaimingAmount] = useState("0");
  const [claimedAmount, setClaimedAmount] = useState("0");
  const [lockedAmount, setLockedAmount] = useState("0");
  const { claimStep, setClaimStep, setIsLoading } = useGlobalContext();

  const { ordinalAccount, disconnectWallet, switchNetwork, walletMethod } =
    useWACContext();

  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        if (walletMethod === WALLET_METHOD.UNISAT) {
          const _network = await window.unisat.getNetwork();
          if (_network !== UNISAT_NETWORK) {
            toast.error(UNISAT_NETWORK_ALERT);
            switchNetwork();
            setClaimStep(ClaimPageStep.CONNECT);
            disconnectWallet();
            StopTimer();
            return;
          }
        }

        setIsLoading(true);
        const { status, data } = await fetchServerData({
          url: "/check-wallet",
          method: API_STYLE.POST,
          param: {
            ordinal_address: ordinalAccount,
            company_name,
          },
        });
        if (status === 200) {
          setClaims(data?.claims);
          setLockedAmount(data?.lockedAmount);
          if (claimStep <= ClaimPageStep.CONNECT) {
            setClaimStep(ClaimPageStep.CLAIM);
          }
          setNewClaim(data?.newClaim ?? {});
        } else if (status === 405) {
          disconnectWallet();
        } else if (status === 406) {
          disconnectWallet();
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    if (ordinalAccount) {
      fetchClaimData();
    } else {
      setClaims([]);
      StopTimer();
      setIsLoading(false);
    }
  }, [ordinalAccount]);

  const checkAndUpdateClaim = async () => {
    try {
      const { status, data } = await fetchServerData({
        url: "/check-runes",
        method: API_STYLE.POST,
        param: {
          ordinal_address: ordinalAccount,
          company_name,
        },
      });

      if (status === 200) {
        setClaims(data?.claims);
      }
    } catch (err) {}
  };

  const RunTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (!timerRef.current) {
      timerRef.current = setTimeout(() => {
        checkAndUpdateClaim();
        RunTimer();
      }, 60000);
    }
  };

  const StopTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  useEffect(() => {
    let claimable = bigInt(0);
    let claiming = bigInt(0);
    let claimed = bigInt(0);

    claims.map((_claim) => {
      if (_claim?.status === ClaimStatus.DEFAULT)
        claimable = claimable.add(bigInt(_claim?.amount));
      else if (_claim?.status === ClaimStatus.CLAIMING)
        claiming = claiming.add(bigInt(_claim?.amount));
      else claimed = claimed.add(bigInt(_claim?.amount));
    });

    setClaimableAmount(claimable.toString());
    setClaimingAmount(claiming.toString());
    setClaimedAmount(claimed.toString());

    if (claiming.gt(bigInt(0))) RunTimer();
    else {
      StopTimer();
    }
  }, [claims]);

  return (
    <ClaimContext.Provider
      value={{
        claims,
        newClaim,
        txStatus,
        lockedAmount,
        claimStatus,
        claimableAmount,
        claimedAmount,
        claimingAmount,
        setClaims,
        setNewClaim,
        setTxStatus,
        setClaimStatus,
      }}
    >
      {children}
    </ClaimContext.Provider>
  );
};

export default ClaimProvider;
