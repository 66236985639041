export const ClaimStatus = {
  DEFAULT: "default",
  CLAIMING: "claiming",
  CLAIMED: "claimed",
} as const;

export type ClaimStatusKey = keyof typeof ClaimStatus;

export type ClaimStatusType = (typeof ClaimStatus)[keyof typeof ClaimStatus];

export type Claim = {
  _id: string;
  walletAddress: string;
  amount: string;
  status: ClaimStatusType;
  orderId?: string;
  psbtHex?: string;
  runeTxId?: string;
  updatedAt?: string;
};

export type MetaDataType = {
  name: string;
  inscriptionNumber: string;
  tokenAmount: number;
};

export const TxStatus = {
  INITIATE: 0,
  CLAMING: 1,
  TRANSFERING: 2,
  SUCCESS: 3,
} as const;

export const TxStatusLabels = [
  "Transaction initiated",
  "BTC received. Inscribing Transfer.",
  "Transfer Token initiated.",
  "Token claim completed.",
];

export type TxStatusType = keyof typeof TxStatus;
