import { useEffect, useState } from "react";
import telegram from "../../assets/img/telegram.png";
import twitter from "../../assets/img/twitter.png";

import styles from "./index.module.scss";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className={`${styles.container} ${scrolling ? styles.scrolled : ""}`}
      >
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <a href="/" onClick={scrollToTop}>
              <img
                src={"https://www.ordict.com/logo.svg"}
                alt="logo"
                loading="lazy"
              />
            </a>
          </div>
          <div className="social-media-links">
            <div className="telegramLogo">
              <a
                href="https://t.me/ordict_official"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/Group 443251550.svg"
                  width={31}
                  height={31}
                  alt="telegram"
                />
              </a>
            </div>
            <div className="twitterLogo">
              <a
                href="https://twitter.com/Ordict_official"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/x.png" width={31} height={31} alt="twitter" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
