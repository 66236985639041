import { useContext } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";

const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error(
      "useWalletContext must be used within a ThemecontextProvider"
    );
  }

  const {
    modals,
    isLoading,
    claimStep,
    setClaimStep,
    setIsLoading,
    setModals,
    openModal,
    closeModal,
  } = context;

  return {
    modals,
    isLoading,
    claimStep,
    setIsLoading,
    setClaimStep,
    setModals,
    openModal,
    closeModal,
  };
};
export default useGlobalContext;
