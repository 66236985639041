import clx from "classnames";
import styles from "./index.module.scss";
import close from "../../assets/img/modal-close.png";
import congrats from "../../assets/img/congrats.png";
import telegram from "../../assets/img/telegram.png";
import twitter from "../../assets/img/twitter.png";
import done from "../../assets/img/done.webp";
import { MODAL_STATUS, MODAL_STATUS_TYPE } from "src/types";
import { MEMPOOL_URL } from "src/config";
import { getReducedText } from "src/utils";

const TxModal: React.FC<{
  txId: string | undefined;
  status: MODAL_STATUS_TYPE;
  successText: string;
  progressContent?: React.ReactElement;
  confirmContent: React.ReactElement;
  onConfirm: () => Promise<any>;
  onClose: () => void;
}> = ({
  txId,
  status,
  successText,
  progressContent,
  confirmContent,
  onConfirm,
  onClose,
}) => {
  return (
    <div
      className={clx(styles.confirmModalWrapper, {
        [`${styles.confirmModalWrapper__hidden}`]:
          status === MODAL_STATUS.CLOSE,
      })}
    >
      <div className={styles.confirmModal}>
        <img
          src={close}
          alt="close"
          className={styles.modalClose}
          onClick={onClose}
        />
        {status === MODAL_STATUS.SUCCESS ? (
          <>
            <img src={done} alt="done" className={styles.done} />
            <div className={styles.ognlInfo}>
              {successText}
              <span className={styles.congrats}>
                <img src={congrats} alt="congrats" />
              </span>
            </div>
            <div className={styles.history_title}>
              <span className={styles.history_title__label}>TX ID:</span>
              <a
                className={styles.history_title__value}
                href={`${MEMPOOL_URL}/tx/${txId}`}
                target="_blank"
                rel="noreferrer"
              >
                {getReducedText(txId)}
              </a>
            </div>
            <div className={styles.socialInfo}>
              Your transaction will be confirmed in a few minutes!
            </div>
            <div className={styles.socialIcons}>
              <a href="https://t.me/ordict_official" target="blank">
                <img src={telegram} alt="telegram" loading="lazy" />
              </a>
              <a href="https://twitter.com/ordict_official" target="blank">
                <img src={twitter} alt="twitter" loading="lazy" />
              </a>
            </div>
          </>
        ) : status === MODAL_STATUS.PROGRESS ? (
          <>{progressContent}</>
        ) : (
          <>
            <img
              src="https://www.ordict.com/logo.svg"
              alt="logo"
              className={styles.logo}
            />
            {confirmContent}
            <button onClick={onConfirm} className={styles.confirmButton}>
              Confirm
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TxModal;
