import React, { ReactNode, createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchServerData } from "src/api";
import { UNISAT_NETWORK, UNISAT_NETWORK_ALERT } from "src/config";
import useGlobalContext from "src/hooks/useGlobalContext";
import useWACContext from "src/hooks/useWACContext";
import { API_STYLE } from "src/types";
import { UnspentOutput } from "src/types/utxo";
import { ClaimPageStep } from "src/types/claimPageStep";
import { WALLET_METHOD } from "src/types/contexts";
import bigInt from "big-integer";
import { useParams } from "react-router-dom";

interface UtxoContextProps {
  totalRunes: string;
  totalSats: string;
  btcUtxos: UnspentOutput[];
  runeUtxos: UnspentOutput[];
  setBtcUtxos: (value: UnspentOutput[]) => void;
  setRuneUtxos: (value: UnspentOutput[]) => void;
}

export const UtxoContext = createContext<UtxoContextProps>(
  {} as UtxoContextProps
);

interface UtxoProviderProps {
  children: ReactNode;
}

export const UtxoProvider: React.FC<UtxoProviderProps> = ({ children }) => {
  // const { company: company_name } = useParams();
  const company_name = "ordict";

  const [totalSats, setTotalSats] = useState("0");
  const [totalRunes, setTotalRunes] = useState("0");
  const [btcUtxos, setBtcUtxos] = useState<UnspentOutput[]>([]);
  const [runeUtxos, setRuneUtxos] = useState<UnspentOutput[]>([]);
  const {
    walletMethod,
    ordinalAccount,
    paymentAccount,
    switchNetwork,
    disconnectWallet,
  } = useWACContext();
  const { claimStep, setClaimStep, setIsLoading } = useGlobalContext();

  useEffect(() => {
    const fetchRuneUtxosData = async () => {
      try {
        if (walletMethod === WALLET_METHOD.UNISAT) {
          const _network = await window.unisat.getNetwork();
          if (_network !== UNISAT_NETWORK) {
            toast.error(UNISAT_NETWORK_ALERT);
            switchNetwork();
            setClaimStep(ClaimPageStep.CONNECT);
            disconnectWallet();
            return;
          }
        }

        setIsLoading(true);
        const { status, data } = await fetchServerData({
          url: "/utxos/rune-utxos",
          method: API_STYLE.POST,
          param: {
            address: ordinalAccount,
            company_name,
          },
        });
        if (status === 200) {
          setRuneUtxos(data.utxos);

          let runeAmount = bigInt(0);
          data.utxos?.forEach((_utxo: any) => {
            if (_utxo.runes) {
              _utxo.runes?.forEach((_rune: any) => {
                if (_rune.runeid === data?.rune_id) {
                  runeAmount = runeAmount.plus(bigInt(_rune.amount));
                }
              });
            }
          });
          setTotalRunes(runeAmount.toString());
          if (claimStep <= ClaimPageStep.CONNECT) {
            setClaimStep(ClaimPageStep.CLAIM);
          }
        } else if (status === 405) {
          disconnectWallet();
        } else if (status === 406) {
          disconnectWallet();
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    if (ordinalAccount && company_name) {
      fetchRuneUtxosData();
    } else {
      setRuneUtxos([]);
      setIsLoading(false);
    }
  }, [ordinalAccount, company_name]);

  useEffect(() => {
    const fetchBtcUtxoData = async () => {
      try {
        const { status, data } = await fetchServerData({
          url: `/utxos/btc-utxos/${ordinalAccount}`,
        });

        if (status === 200) {
          setBtcUtxos(data.utxos);

          let satsAmount = bigInt(0);
          data.utxos?.forEach((_utxo: any) => {
            satsAmount = satsAmount.plus(bigInt(_utxo.satoshis));
          });
          setTotalSats(satsAmount.toString());
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    if (paymentAccount) {
      fetchBtcUtxoData();
    } else {
    }
  }, [paymentAccount]);

  return (
    <UtxoContext.Provider
      value={{
        totalRunes,
        totalSats,
        btcUtxos,
        runeUtxos,
        setBtcUtxos,
        setRuneUtxos,
      }}
    >
      {children}
    </UtxoContext.Provider>
  );
};

export default UtxoProvider;
