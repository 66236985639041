import React from "react";
import _ from "lodash";
import styles from "./index.module.scss";
import { getReducedText, numberWithCommas } from "src/utils";
import { MEMPOOL_URL } from "src/config";
import { UnspentOutput } from "src/types/utxo";
import bigInt from "big-integer";

const UtxoTable: React.FC<{ utxos: UnspentOutput[] }> = ({ utxos }) => {
  return (
    <>
      <table className={styles.historyTable}>
        <thead>
          <tr className={styles.historyTableHeaderRow}>
            <th className={styles.historyTableHeaderCell}>TXID</th>
            <th className={styles.historyTableHeaderCell}>SATOSHIS</th>
            <th className={styles.historyTableHeaderCell}>RUNE</th>
          </tr>
        </thead>
        <tbody>
          {utxos?.length > 0 &&
            utxos.map((_utxo, idx) => {
              let fromRuneAmount = bigInt(0);
              _utxo?.runes?.forEach((_rune) => {
                if (_rune.runeid) {
                  fromRuneAmount = fromRuneAmount.plus(bigInt(_rune.amount));
                }
              });
              return (
                <tr key={idx} className={styles.historyTableRow}>
                  <td className={`${styles.historyTableCell}`}>
                    <a
                      href={`${MEMPOOL_URL}/tx/${_utxo?.txid}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getReducedText(_utxo.txid)}
                    </a>
                  </td>
                  <td className={`${styles.historyTableCell}`}>
                    {numberWithCommas(_utxo.satoshis)}
                  </td>
                  <td className={`${styles.historyTableCell}`}>
                    {numberWithCommas(fromRuneAmount.toString())}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {_.isEmpty(utxos) && <div className={styles.emptyData}>Empty Data</div>}
    </>
  );
};

export default UtxoTable;
