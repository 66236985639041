import { useState } from "react";
import _ from "lodash";
import bigInt from "big-integer";
import { toast } from "react-toastify";
import { signTransaction } from "sats-connect";
import Footer from "../../components/Footer";
import Container from "../../components/Container";
import styles from "./index.module.scss";
import backgroundImage from "../../assets/img/linedBackground.png";
import { numberWithCommas, replaceMiddleWithDots } from "src/utils";
import { MEMPOOL_URL, XVERSE_NETWORK } from "src/config";
import useWACContext from "src/hooks/useWACContext";
import useGlobalContext from "src/hooks/useGlobalContext";
import { ClaimPageStep } from "src/types/claimPageStep";
import useClaimContext from "src/hooks/useClaimContext";
import { fetchServerData } from "src/api";
import { WALLET_METHOD } from "src/types/contexts";
import { ClaimStatus, TxStatus } from "src/types/claim";
import TxHistory from "../../components/TxHistory";
import { API_STYLE, MODAL_STATUS, MODAL_STATUS_TYPE } from "src/types";
import TxModal from "../../components/TxModal";
import { SelectWallet } from "src/components/SelectWallet";

const ClaimRune = () => {
  const { setIsLoading, claimStep, setClaimStep } = useGlobalContext();
  const {
    isConnected,
    ordinalAccount,
    paymentAccount,
    paymentPubkey,
    walletMethod,
  } = useWACContext();
  const {
    claimableAmount,
    claimingAmount,
    claimedAmount,
    lockedAmount,
    setClaims,
    setTxStatus,
    setClaimStatus,
  } = useClaimContext();
  const company_name = "ordict";

  const [_claimedAmount, setClaimedAmount] = useState("0");
  const [txId, setTxId] = useState("");
  const [claimModalOpen, setClaimModalOpen] = useState<MODAL_STATUS_TYPE>(
    MODAL_STATUS.CLOSE
  );

  const { disconnectWallet } = useWACContext();

  const handleOpenClaimModal = async () => {
    try {
      if (!claimableAmount)
        return toast.warn("You don't have claimable tokens!");
      setClaimModalOpen(MODAL_STATUS.DEFAULT);
    } catch (error) {
      console.error("Error fetching gas fees:", error);
      // Handle the error appropriately.
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmClaim = async () => {
    handleCloseModal();
    try {
      if (!claimableAmount) {
        toast.warn("You don't have claimable tokens!");
        return;
      }
      setIsLoading(true);
      setClaimedAmount("0");
      setTxId("");
      const { status, data } = await fetchServerData({
        url: `/claim-rune`,
        method: API_STYLE.POST,
        param: {
          ordinal_address: ordinalAccount,
          payment_address: paymentAccount,
          payment_pubkey: paymentPubkey,
          wallet_type: walletMethod,
          company_name,
        },
      });

      if (status === 200) {
        if (_.isEmpty(data?.orderId) || _.isEmpty(data?.toSignInputs)) {
          toast.error("Something went wrong");
          return;
        }

        let psbt;
        if (walletMethod === WALLET_METHOD.UNISAT) {
          const btcToSignInputs = data?.toSignInputs.filter(
            (v: any) => v.publicKey === paymentPubkey
          );
          psbt = await window.unisat.signPsbt(data?.psbt, {
            autoFinalized: true,
            toSignInputs: btcToSignInputs,
          });

          if (_.isEmpty(psbt)) {
            toast.error("Transaction Sign Error");
            return;
          }
        } else {
          const signInputs = data?.toSignInputs
            .filter((_input: any) => _input?.address === paymentAccount)
            .map((_input: any) => {
              return _input.index;
            });

          await signTransaction({
            payload: {
              network: {
                type: XVERSE_NETWORK,
              },
              psbtBase64: data?.psbt,
              broadcast: false,
              message: "Ordict Claim",
              inputsToSign: [
                {
                  address: paymentAccount!,
                  signingIndexes: signInputs,
                },
              ],
            },
            onFinish: (response: any) => {
              console.log(response.psbtBase64);
              psbt = response?.psbtBase64;
            },
            onCancel: () => {
              setIsLoading(false);
              toast.error("Transaction rejected");
              return;
            },
          });
          if (_.isEmpty(psbt)) return;
        }

        const { status: _status, data: _data } = await fetchServerData({
          url: `/rune-broadcast`,
          method: API_STYLE.POST,
          param: {
            ordinal_address: ordinalAccount,
            payment_address: paymentAccount,
            psbt,
            signedPSBT: psbt,
            toSignInputs: data?.toSignInputs,
            orderId: data?.orderId,
            wallet_type: walletMethod,
            company_name,
          },
        });

        if (_status === 200) {
          setClaimedAmount(claimableAmount);
          setClaims(_data?.claims);
          setTxId(_data?.txId);
          setClaimModalOpen(MODAL_STATUS.SUCCESS);
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setClaimModalOpen(MODAL_STATUS.CLOSE);
  };

  return (
    <>
      <Container bgImage={backgroundImage}>
        <div className={styles.container}>
          <div>
            <div>
              <h1>Claim $ORDICT.RUNES.BTC Token Allocation</h1>
            </div>
            <div>
              {claimStep === ClaimPageStep.DEFAULT ? (
                <div className={styles.connect_wrapper}>
                  <button
                    onClick={() => {
                      if (!isConnected) {
                        setClaimStep(ClaimPageStep.CONNECT);
                      }
                    }}
                    className={styles.connectWallet}
                  >
                    Connect
                  </button>

                  <p className={styles.connect_label}>
                    connect wallet to check your allocation
                  </p>
                </div>
              ) : claimStep === ClaimPageStep.CONNECT ? (
                <SelectWallet isOpen={claimStep === ClaimPageStep.CONNECT} />
              ) : (
                <div>
                  <div>
                    <div className={styles.connected_wallet_wrapper}>
                      <div className={styles.connected_address}>
                        <span className={styles.connected_address__label}>
                          Connected wallet:{" "}
                        </span>
                        <div
                          className={styles.connected_address__address}
                          onClick={() =>
                            window.open(
                              `${MEMPOOL_URL}/address/${ordinalAccount}`,
                              "_blank"
                            )
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            cursor: "pointer",
                          }}
                        >
                          <span className="text-white font-sans font-bold text-xl text-shadow signika">
                            {replaceMiddleWithDots(
                              ordinalAccount?.toString() ?? ""
                            )}
                          </span>
                          <div style={{ cursor: "pointer" }}>
                            <svg
                              className="h-8 w-8 text-white"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                              <line x1="10" y1="14" x2="20" y2="4" />
                              <polyline points="15 4 20 4 20 9" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <button
                        className={styles.connectWallet}
                        onClick={() => {
                          setClaimStatus(ClaimStatus.DEFAULT);
                          setTxStatus(TxStatus.INITIATE);
                          disconnectWallet();
                        }}
                      >
                        Disconnect
                      </button>
                    </div>
                    <div className={styles.allocation}>
                      <div className={styles.allocation_total}>
                        <span className={styles.allocation_total__label}>
                          Total Allocation:{" "}
                        </span>
                        <span className={styles.allocation_total__value}>
                          {numberWithCommas(
                            bigInt(lockedAmount)
                              .add(bigInt(claimableAmount))
                              .add(bigInt(claimingAmount))
                              .add(bigInt(claimedAmount))
                              .toString()
                          )}{" "}
                          Tokens
                        </span>
                      </div>
                      <div className={styles.allocation_status}>
                        <span className={styles.allocation_status__label}>
                          Claimable Now:{" "}
                        </span>
                        <span className={styles.allocation_status__value}>
                          {numberWithCommas(claimableAmount)} Tokens
                        </span>
                      </div>
                      <div className={styles.allocation_status}>
                        <span className={styles.allocation_status__label}>
                          Claimed Amount:{" "}
                        </span>
                        <span className={styles.allocation_status__value}>
                          {numberWithCommas(claimedAmount)} Tokens
                        </span>
                      </div>
                      <div className={styles.allocation_status}>
                        <span className={styles.allocation_status__label}>
                          Locked Amount:{" "}
                        </span>
                        <span className={styles.allocation_status__value}>
                          {numberWithCommas(lockedAmount)} Tokens
                        </span>
                      </div>
                      <div className={styles.allocation_status}>
                        <span className={styles.allocation_status__label}>
                          Last Token Unlock:{" "}
                        </span>
                        <span className={styles.allocation_status__value}>
                          31-08-2024
                        </span>
                      </div>
                    </div>

                    <div className={styles.allocation_status}>
                      <span className={styles.allocation_status__label}>
                        Note: Tokens are released linearly at 05:00 UTC daily
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {claimStep > ClaimPageStep.CONNECT && (
              <div className={styles.allocation_header}>
                <div className={styles.confirmed_allocation}>
                  READY TO CLAIM tokens :&nbsp;&nbsp;&nbsp;
                  <span
                    className="text-white"
                    style={{ wordBreak: "break-all", color: "#f47115" }}
                    rel="noreferrer"
                  >
                    {numberWithCommas(claimableAmount)}
                  </span>
                </div>
                <button
                  disabled={!claimableAmount}
                  className={styles.connectWallet}
                  onClick={handleOpenClaimModal}
                >
                  Claim Tokens
                </button>
              </div>
            )}

            {claimStep > ClaimPageStep.CONNECT && isConnected && (
              <div style={{ marginTop: "10px", marginBottom: "40px" }}>
                <TxHistory />
              </div>
            )}
          </div>
        </div>
        <TxModal
          status={claimModalOpen}
          txId={txId}
          confirmContent={
            <div className={styles.successContent}>
              <div className={styles.ognlInfo}>
                Great, You are claiming {claimableAmount} $Ordict tokens
              </div>
              <div className={styles.olngTokenContainer}>
                {claimableAmount} <span>$Ordict tokens</span>
              </div>
            </div>
          }
          successText={`Congratulations! You've successfully claimed ${_claimedAmount} $Ordict tokens`}
          onConfirm={handleConfirmClaim}
          onClose={handleCloseModal}
        />
      </Container>
      <Footer />
    </>
  );
};

export default ClaimRune;
