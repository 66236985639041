import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { MEMPOOL_API_URL, ORDINALBOT_URL, SERVER_URL } from "src/config";
import { API_STYLE } from "src/types";

export interface WhitelistData {
  walletAddress: string;
  twitterHandle: string;
  telegramUsername: string;
  thoughts: string;
  [key: string]: string;
}

export const fetchServerData = async ({
  url,
  param = undefined,
  withAuth = true,
  withForm = false,
  method = API_STYLE.GET,
  headers = {
    "Content-Type": "application/json",
  },
}: {
  url: string;
  method?: string;
  withAuth?: boolean;
  withForm?: boolean;
  param?: any;
  headers?: Record<string, any>;
}) => {
  let customHeaders = headers;
  if (withAuth) {
    customHeaders = {
      ...headers,
    };
  }

  if (withForm) {
    customHeaders = {
      ...headers,
      "Content-Type": "multipart/form-data",
    };
  }

  try {
    const response = await axios({
      method,
      headers: customHeaders,
      data: param,
      url: `${SERVER_URL}${url}`,
    });

    return { status: response.status, data: response?.data };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const axiosError = err as AxiosError<Record<string, any>>;
      toast.error(axiosError.response?.data?.message);

      return {
        status: axiosError.response?.status,
        data: { message: axiosError.response?.data?.message },
      };
    }
    toast.error("Unexpected Error");

    return {
      status: 500,
      data: { message: "Unexpected Error" },
    };
  }
};

export const fetchOrdinalbotAPI = async ({
  url,
  method = API_STYLE.GET,
  param,
  headers = {
    "Content-Type": "application/json",
  },
}: {
  url: string;
  method?: string;
  withAuth?: boolean;
  withForm?: boolean;
  param?: any;
  headers?: Record<string, any>;
}) => {
  try {
    const response = await axios({
      method,
      headers,
      data: param,
      url: `${ORDINALBOT_URL}${url}`,
    });

    return { status: response.status, data: response?.data };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const axiosError = err as AxiosError<Record<string, any>>;
      toast.error(axiosError.response?.data?.message);

      return {
        status: axiosError.response?.status,
        data: { message: axiosError.response?.data?.message },
      };
    }
    toast.error("Unexpected Error");

    return {
      status: 500,
      data: { message: "Unexpected Error" },
    };
  }
};

export const fetchMempoolAPI = async ({
  url,
  method = API_STYLE.GET,
}: {
  url: string;
  method?: string;
}) => {
  try {
    const response = await axios({
      method,
      url: `${MEMPOOL_API_URL}${url}`,
    });

    return { status: response.status, data: response?.data };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const axiosError = err as AxiosError<Record<string, any>>;
      toast.error(axiosError.response?.data?.message);

      return {
        status: axiosError.response?.status,
        data: { message: axiosError.response?.data?.message },
      };
    }
    toast.error("Unexpected Error");

    return {
      status: 500,
      data: { message: "Unexpected Error" },
    };
  }
};
