export const BTC_NETWORK = {
  LIVENET: "livenet",
  TESTNET: "testnet",
} as const;

export type BTC_NETWORK_TYPE = (typeof BTC_NETWORK)[keyof typeof BTC_NETWORK];

export const WALLET_METHOD = {
  UNISAT: "unisat",
  XVERSE: "xverse",
} as const;

export type WALLET_METHOD_TYPE =
  (typeof WALLET_METHOD)[keyof typeof WALLET_METHOD];

export const RECOMMENDED_FEES = {
  fastestFee: "12",
  halfHourFee: "12",
  hourFee: "12",
  economyFee: "12",
  minimumFee: "12",
};

export interface BtcBalance {
  confirmed: number;
  unconfirmed: number;
  total: number;
}
