export const API_STYLE = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  DELETE: "DELETE",
};

export type API_STYLE_TYPE = (typeof API_STYLE)[keyof typeof API_STYLE];
export type MODAL_DATA_TYPE = {
  opened: boolean;
  params?: Record<string, any>;
};

export const MODAL_STATUS = {
  CLOSE: 0,
  DEFAULT: 1,
  SUCCESS: 2,
  PROGRESS: 3,
  ERROR: 4,
} as const;

export type MODAL_STATUS_TYPE =
  (typeof MODAL_STATUS)[keyof typeof MODAL_STATUS];
