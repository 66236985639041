import useGlobalContext from "src/hooks/useGlobalContext";
import styles from "./index.module.scss";
import clx from "classnames";

const PageLoader = () => {
  const { isLoading } = useGlobalContext();
  return (
    <div
      className={clx(styles.loader_container, {
        [styles.hidden]: !isLoading,
      })}
    >
      <div className={styles.loader}></div>
    </div>
  );
};

export default PageLoader;
