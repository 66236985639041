import { useState } from "react";
import _ from "lodash";
import bigInt from "big-integer";
import { toast } from "react-toastify";
import { signTransaction } from "sats-connect";
import Footer from "../../components/Footer/";
import { SelectWallet } from "../../components/SelectWallet";
import Container from "../../components/Container";
import styles from "./index.module.scss";
import {
  convertToIntegerWithComma,
  numberWithCommas,
  replaceMiddleWithDots,
} from "src/utils";
import { MEMPOOL_URL, XVERSE_NETWORK } from "src/config";
import useWACContext from "src/hooks/useWACContext";
import useGlobalContext from "src/hooks/useGlobalContext";
import { ClaimPageStep } from "src/types/claimPageStep";
import UtxoList from "../../components/UtxoList";
import useUtxoContext from "src/hooks/useUtxoContext";
import { fetchServerData } from "src/api";
import { API_STYLE, MODAL_STATUS, MODAL_STATUS_TYPE } from "src/types";
import { WALLET_METHOD } from "src/types/contexts";
import backgroundImage from "../../assets/img/linedBackground.png";
import TxModal from "src/components/TxModal";
import CInput from "src/components/CInput";

const SplitRune = () => {
  // const { company: company_name } = useParams();
  const company_name = "ordict";

  const { claimStep, setIsLoading, setClaimStep } = useGlobalContext();
  const {
    isConnected,
    ordinalAccount,
    paymentAccount,
    paymentPubkey,
    walletMethod,
    disconnectWallet,
  } = useWACContext();
  const { btcUtxos, runeUtxos, totalRunes, totalSats } = useUtxoContext();
  const [txId, setTxId] = useState("");
  const [utxoAmount, setUtxoAmount] = useState("");
  const [runePerUtxo, setRunePerUtxo] = useState("");
  const [receverAddress, setReceiverAddress] = useState(
    "tb1p33vj905l0ng4l6ev485xchkjw3ukwaepsd3zw8al8mspulxf5qeqlhase7"
  );
  const [splitModalOpen, setSplitModalOpen] = useState<MODAL_STATUS_TYPE>(
    MODAL_STATUS.CLOSE
  );

  const handleOpenClaimModal = async () => {
    try {
      const _utxoAmount = bigInt(utxoAmount.replaceAll(",", ""));
      const _runeAmount = bigInt(runePerUtxo.replaceAll(",", ""));
      if (_utxoAmount.leq(bigInt(0))) return toast.error("Invalid Utxo Amount");
      if (_runeAmount.leq(bigInt(0))) return toast.error("Invalid Rune Amount");
      if (!receverAddress) return toast.error("Invalid Receiver Address");

      setSplitModalOpen(MODAL_STATUS.DEFAULT);
    } catch (error) {
      console.error("Error fetching gas fees:", error);
      // Handle the error appropriately.
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setSplitModalOpen(MODAL_STATUS.CLOSE);
  };

  const onChangeRuneAmount = (value: string) => {
    setRunePerUtxo(convertToIntegerWithComma(value));
  };

  const onChangeUtxoAmount = (value: string) => {
    setUtxoAmount(convertToIntegerWithComma(value));
  };

  const handleConfirmSplit = async () => {
    try {
      const _utxoAmount = bigInt(utxoAmount.replaceAll(",", ""));
      const _runeAmount = bigInt(runePerUtxo.replaceAll(",", ""));
      if (_utxoAmount.leq(bigInt(0))) return toast.error("Invalid Utxo Amount");
      if (_runeAmount.leq(bigInt(0))) return toast.error("Invalid Rune Amount");
      if (!receverAddress) return toast.error("Invalid Receiver Address");

      setIsLoading(true);
      const { status, data } = await fetchServerData({
        url: `/utxos/split`,
        method: API_STYLE.POST,
        param: {
          ordinal_address: ordinalAccount,
          payment_address: paymentAccount,
          payment_pubkey: paymentPubkey,
          wallet_type: walletMethod,
          company_name,
          rune_amount: _runeAmount,
          utxo_amount: _utxoAmount,
          receiver_address: receverAddress,
        },
      });

      if (status === 200) {
        console.log(data);
        if (_.isEmpty(data?.toSignInputs))
          return toast.error("Something went wrong");

        let psbt;
        if (walletMethod === WALLET_METHOD.UNISAT) {
          const btcToSignInputs = data?.toSignInputs.filter(
            (v: any) => v.publicKey === paymentPubkey
          );
          psbt = await window.unisat.signPsbt(data?.psbt, {
            autoFinalized: true,
            toSignInputs: btcToSignInputs,
          });

          if (_.isEmpty(psbt)) return toast.error("Transaction Sign Error");
          const _txid = await window.unisat.pushPsbt(psbt);
          setTxId(_txid);
          setSplitModalOpen(MODAL_STATUS.SUCCESS);
          console.log(_txid);
        } else {
          const signInputs = data?.toSignInputs
            .filter((_input: any) => _input?.address === paymentAccount)
            .map((_input: any) => {
              return _input.index;
            });

          await signTransaction({
            payload: {
              network: {
                type: XVERSE_NETWORK,
              },
              psbtBase64: data?.psbt,
              broadcast: true,
              message: "ORDICT Claim",
              inputsToSign: [
                {
                  address: paymentAccount!,
                  signingIndexes: signInputs,
                },
              ],
            },
            onFinish: (response: any) => {
              console.log(response.txId);
              setTxId(response.txId);
              setSplitModalOpen(MODAL_STATUS.SUCCESS);
            },
            onCancel: () => {
              setIsLoading(false);
              return toast.error("Transaction rejected");
            },
          });
          if (_.isEmpty(psbt)) return;
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container bgImage={backgroundImage}>
        <div className={styles.container}>
          <div>
            <div className="w-sm py-4 px-24 mt-24  rounded-sm">
              <div className="pb-4">
                <h1 className="text-white text-5xl text-shadow">
                  Split UTXOs for Runes management
                </h1>
              </div>

              <div>
                {claimStep === ClaimPageStep.DEFAULT ? (
                  <div className={styles.connect_wrapper}>
                    <button
                      onClick={() => {
                        if (!isConnected) {
                          setClaimStep(ClaimPageStep.CONNECT);
                        }
                      }}
                      className={styles.connectWallet}
                    >
                      Connect
                    </button>

                    <p className={styles.connect_label}>
                      Connect wallet to split UTXOs
                    </p>
                  </div>
                ) : claimStep === ClaimPageStep.CONNECT ? (
                  <SelectWallet isOpen={claimStep === ClaimPageStep.CONNECT} />
                ) : (
                  <div className={styles.content}>
                    <div className={styles.connected_wallet_wrapper}>
                      <div className={styles.connected_address}>
                        <span className={styles.connected_address__label}>
                          Connected wallet:{" "}
                        </span>
                        <div
                          className={styles.connected_address__address}
                          onClick={() =>
                            window.open(
                              `${MEMPOOL_URL}/address/${ordinalAccount}`,
                              "_blank"
                            )
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            cursor: "pointer",
                          }}
                        >
                          <span className="text-white font-sans font-bold text-xl text-shadow signika">
                            {replaceMiddleWithDots(
                              ordinalAccount?.toString() ?? ""
                            )}
                          </span>
                          <div style={{ cursor: "pointer" }}>
                            <svg
                              className="h-8 w-8 text-white"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                              <line x1="10" y1="14" x2="20" y2="4" />
                              <polyline points="15 4 20 4 20 9" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <button
                        className={styles.connectWallet}
                        onClick={() => {
                          disconnectWallet();
                        }}
                      >
                        Disconnect
                      </button>
                    </div>
                    <div className={styles.allocation}>
                      <div className={styles.allocation_status}>
                        <span className={styles.allocation_status__label}>
                          Available Satoshis:{" "}
                        </span>
                        <span className={styles.allocation_status__value}>
                          {numberWithCommas(totalSats)} Sats
                        </span>
                      </div>
                      <div className={styles.allocation_status}>
                        <span className={styles.allocation_status__label}>
                          Total Runes:{" "}
                        </span>
                        <span className={styles.allocation_status__value}>
                          {numberWithCommas(totalRunes)} Tokens
                        </span>
                      </div>
                    </div>
                    <div className={styles.formWrapper}>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrapper}>
                          <CInput
                            fill
                            placeholder="# of Runes per UTXO"
                            onChange={onChangeRuneAmount}
                            value={runePerUtxo}
                          />
                          <CInput
                            fill
                            placeholder="# of UTXOs"
                            onChange={onChangeUtxoAmount}
                            value={runePerUtxo}
                          />
                        </div>
                      </div>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrapper}>
                          <CInput
                            fill
                            placeholder="Receiver Address"
                            onChange={setReceiverAddress}
                            value={receverAddress}
                          />

                          <button
                            onClick={handleOpenClaimModal}
                            className={styles.connectWallet}
                          >
                            Split
                          </button>
                        </div>
                      </div>
                    </div>

                    <UtxoList title="BTC UTXOS" utxos={btcUtxos} />
                    <UtxoList title="RUNE UTXOS" utxos={runeUtxos} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <TxModal
          status={splitModalOpen}
          txId={txId}
          confirmContent={
            <div className={styles.successContent}>
              <div className={styles.ognlInfo}>
                Great, You are spliting $Ordict tokens
              </div>
              <div className={styles.olngTokenContainer}>
                <span>{runePerUtxo} Runes per UTXO</span>
                <span>{utxoAmount} UTXOs</span>
              </div>
            </div>
          }
          successText={`Congratulations! You've successfully splited $Ordict tokens`}
          onConfirm={handleConfirmSplit}
          onClose={handleCloseModal}
        />
      </Container>
      <Footer />
    </>
  );
};

export default SplitRune;
