import styles from "./index.module.scss";
import xverse from "../../assets/img/x-verse.png";
import unisat from "../../assets/img/unisat.jpg";
import { WALLET_METHOD, WALLET_METHOD_TYPE } from "src/types/contexts";
import useGlobalContext from "src/hooks/useGlobalContext";
import useWACContext from "src/hooks/useWACContext";
import { ClaimPageStep } from "src/types/claimPageStep";

export const SelectWallet = (props: IModal) => {
  const { setIsLoading, setClaimStep } = useGlobalContext();
  const { connectWallet } = useWACContext();

  const onConnectClick = async (walletType: WALLET_METHOD_TYPE) => {
    setIsLoading(true);

    const res = await connectWallet(walletType);
    if (!res) setIsLoading(false);
  };

  return (
    <div className={styles.select_walelt} id="modal">
      <div>
        <div onClick={() => setClaimStep(ClaimPageStep.DEFAULT)}></div>
        <span>&#8203;</span>
        <div
          className={styles.select_walelt__content}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className={styles.select_walelt__title}>Select Wallet</div>
          <div className={styles.select_walelt__icons}>
            <img
              src={xverse}
              alt="XVerse"
              onClick={() => {
                onConnectClick(WALLET_METHOD.XVERSE);
              }}
              loading="lazy"
            />
            <img
              src={unisat}
              alt="Unisat"
              onClick={() => {
                onConnectClick(WALLET_METHOD.UNISAT);
              }}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface IModal {
  isOpen: boolean;
}
