import React, { createContext, useState, ReactNode } from "react";
import _ from "lodash";
import {
  BTC_NETWORK,
  BTC_NETWORK_TYPE,
  BtcBalance,
  WALLET_METHOD,
  WALLET_METHOD_TYPE,
} from "src/types/contexts";
import { toast } from "react-toastify";
import { AddressPurpose, getAddress } from "sats-connect";
import useGlobalContext from "src/hooks/useGlobalContext";
import { ClaimPageStep } from "src/types/claimPageStep";
import { UNISAT_NETWORK, XVERSE_NETWORK } from "src/config";

declare global {
  interface Window {
    unisat: any;
  }
}

interface WACContextProps {
  walletMethod: WALLET_METHOD_TYPE;
  isConnected: boolean;
  btcBalance: BtcBalance;
  paymentAccount: string | undefined;
  ordinalAccount: string | undefined;
  paymentPubkey: string | undefined;
  network: string;
  connectWallet: (walletType: WALLET_METHOD_TYPE) => Promise<boolean>;
  disconnectWallet: () => void;
  switchNetwork: () => Promise<void>;
}

export const WACContext = createContext<WACContextProps>({} as WACContextProps);

interface WACProviderProps {
  children: ReactNode;
}

export const WACProvider: React.FC<WACProviderProps> = ({ children }) => {
  const [walletMethod, setWalletMethod] = useState<WALLET_METHOD_TYPE>(
    WALLET_METHOD.UNISAT
  );
  const [isConnected, setIsConnected] = useState(false);
  const { setClaimStep, setIsLoading } = useGlobalContext();
  const [btcBalance, setBtcBalance] = useState<BtcBalance>({
    total: 0,
    confirmed: 0,
    unconfirmed: 0,
  });
  const [paymentAccount, setPaymentAccount] = useState<string>();
  const [ordinalAccount, setOrdinalAccount] = useState<string>();
  const [paymentPubkey, setPaymentPubkey] = useState<string>();
  const [network, setNetwork] = useState<BTC_NETWORK_TYPE>(BTC_NETWORK.LIVENET); // Default network
  const unisat = window.unisat;
  const xverse = window.XverseProviders;

  const connectWallet = async (walletType: WALLET_METHOD_TYPE) => {
    try {
      if (walletType === WALLET_METHOD.UNISAT) {
        if (_.isEmpty(unisat))
          return !toast.warn("Unisat Wallet is not installed"!);
        const accounts = await unisat.requestAccounts();
        const pubkey = await unisat.getPublicKey();
        if (_?.isEmpty(accounts))
          return !toast.error("Wallet should has 1 account at least");
        setIsLoading(true);
        setIsConnected(true);
        setOrdinalAccount(accounts[0]);
        setPaymentAccount(accounts[0]);

        setPaymentPubkey(pubkey);
        const currentNetwork = await unisat.getNetwork();
        setNetwork(currentNetwork);

        unisat.on("accountsChanged", async (accounts: Array<string>) => {
          if (accounts.length > 0) {
            setIsConnected(true);
            setOrdinalAccount(accounts[0]);
            setPaymentAccount(accounts[0]);
            setPaymentPubkey(await unisat.getPublicKey());
            const balance = await unisat.getBalance(accounts[0]);
            setBtcBalance(balance);
          }
        });

        unisat.on("networkChanged", async (network: string) => {
          setNetwork(network as BTC_NETWORK_TYPE);
        });

        setWalletMethod(WALLET_METHOD.UNISAT);
      } else {
        if (_.isEmpty(xverse))
          return !toast.warn("Xverse Wallet is not installed"!);
        setIsLoading(true);
        await getAddress({
          payload: {
            purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
            message: "Connect With BRC20 Claim",
            network: {
              type: XVERSE_NETWORK,
            },
          },
          onFinish: (response: any) => {
            const paymentAddressItem = response.addresses.find(
              (address: any) => address.purpose === AddressPurpose.Payment
            );
            setPaymentAccount(paymentAddressItem?.address);
            setPaymentPubkey(paymentAddressItem?.publicKey);
            const ordinalsAddressItem = response.addresses.find(
              (address: any) => address.purpose === AddressPurpose.Ordinals
            );
            setOrdinalAccount(ordinalsAddressItem?.address);
            setWalletMethod(WALLET_METHOD.XVERSE);
            setIsConnected(true);
          },
          onCancel: () => {
            setIsLoading(false);
          },
        });
      }
      return true;
    } catch (err) {
      setIsLoading(false);
      toast.error("Wallet connection is rejected");
      return false;
    } finally {
    }
  };

  const disconnectWallet = () => {
    setIsLoading(false);
    setClaimStep(ClaimPageStep.DEFAULT);
    setIsConnected(false);
    setOrdinalAccount("");
    setPaymentAccount("");
    setPaymentPubkey("");
    setWalletMethod(WALLET_METHOD.UNISAT);
    setBtcBalance({ confirmed: 0, unconfirmed: 0, total: 0 });
  };

  const switchNetwork = async () => {
    const unisat = window.unisat;
    if (unisat) {
      try {
        await unisat.switchNetwork(UNISAT_NETWORK);
        setNetwork(UNISAT_NETWORK);

        // After switching the network, refresh the account details
        const accounts = await unisat.getAccounts();
        const pubkey = await unisat.getPublicKey();
        if (_?.isEmpty(accounts))
          toast.error("Wallet should has 1 account at least");
        if (accounts.length > 0) {
          setOrdinalAccount(accounts[0]);
          setPaymentAccount(accounts[0]);
          setPaymentAccount(pubkey);
          const newBalance = await unisat.getBalance(accounts[0]);
          setBtcBalance(newBalance);
        } else {
          // Handle the case where no accounts are found after the network switch
          setIsConnected(false);
          setOrdinalAccount("");
          setPaymentAccount("");
          setPaymentPubkey("");

          setBtcBalance({ confirmed: 0, unconfirmed: 0, total: 0 });
        }
      } catch (error) {
        console.error("Error switching network:", error);
      }
    }
  };

  return (
    <WACContext.Provider
      value={{
        walletMethod,
        isConnected,
        btcBalance,
        paymentAccount,
        ordinalAccount,
        paymentPubkey,
        network,
        connectWallet,
        disconnectWallet,
        switchNetwork,
      }}
    >
      {children}
    </WACContext.Provider>
  );
};

export default WACProvider;
