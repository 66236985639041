import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-body">
        <div className="logo-wrap">
          <img src="/logo.png" alt="logo" />
        </div>
        <div>
          <hr />
        </div>
        <div className="footer-menu">
          <div className="footer-menu-items">
            <div className="footer-menu-link">
              <Link to="/">Home</Link>
            </div>
            <div className="footer-menu-link ">
              <Link to="/tnc">Terms & Conditions</Link>
            </div>
            <div className="footer-menu-link ">
              <Link to="/privacy">Privacy & Policy</Link>
            </div>
          </div>
          <div className="footer-contact">
            <div className="contact">
              <img src="/footerIcon.png" alt="footerIcon" />
              <span> support@ordict.com</span>
            </div>
            <div className="social-media-links">
              <div className="telegramLogo">
                <a
                  href="https://t.me/ordict_official"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/Group 443251550.svg"
                    width={31}
                    height={31}
                    alt="telegram"
                  />
                </a>
              </div>
              <div className="twitterLogo">
                <a
                  href="https://twitter.com/Ordict_official"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/x.png" width={31} height={31} alt="twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <hr />
        </div>

        <div className="footer-bottom">
          <div>Copyright © 2024 Ordict. All rights reserved.</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
