import React from "react";
import clx from "classnames";
import styles from "./index.module.scss";
import UtxoTable from "../UtxoTable";
import { UnspentOutput } from "src/types/utxo";
import _ from "lodash";
import { OrderData } from "src/types/inscribe";
import OrderTable from "../OrderTable";

const UtxoList: React.FC<{
  title: string;
  utxos?: UnspentOutput[];
  orders?: OrderData[];
}> = ({ title, utxos, orders }) => {
  return (
    <div className={styles.history_wrapper}>
      <div className={styles.history_header}>
        <span>{title}</span>
      </div>
      {!_.isEmpty(utxos) && (
        <div className={clx(styles.history_content)}>
          <UtxoTable utxos={utxos!} />
        </div>
      )}
      {!_.isEmpty(orders) && (
        <div className={clx(styles.history_content)}>
          <OrderTable orders={orders!} />
        </div>
      )}
      {_.isEmpty(utxos) && _.isEmpty(orders) && (
        <div className={styles.emptyData}>Empty Data</div>
      )}
    </div>
  );
};

export default UtxoList;
