export type InscribeFileData = {
  filename: string;
  dataURL: string;
  size: number;
  type?: string;
};

export type OrderData = {
  id: string;
  status: OrderStatusType;
  inscription?: string;
  createAt: string;
};

export const OrderStatus = {
  DEFAULT: "waiting-payment",
  PAYMENT_CONFIRMATION: "waiting-confirmation",
  PARENT: "waiting-parent",
  QUEUE: "queued",
  MEMPOOL: "mempool",
  COMPLETED: "completed",
} as const;

export type OrderStatusKey = keyof typeof OrderStatus;
export type OrderStatusType = (typeof OrderStatus)[keyof typeof OrderStatus];

export type OrderDetail = {
  id: string;
  fee: number;
  totalFee: number;
  paymentAddress: string;
  parent?: string;
  depositAddress?: string;
  returnAddress: string;
  receiveAddress: string;
  commitTx?: string;
  revealTx?: string;
  status: OrderStatusType;
};

export const CopyLabel = {
  DEFAULT: "NONE",
  INSCRIPTION: "INSCRIPTION",
  DEPOSIT: "DEPOSIT",
  PAYMENT: "PAYMENT",
  COMMIT: "COMMIT",
  REVEAL: "REVEAL",
} as const;
export type CopyLabelType = (typeof CopyLabel)[keyof typeof CopyLabel];
