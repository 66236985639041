import { useContext } from "react";
import { UtxoContext } from "src/contexts/UtxoContext";

const useUtxoContext = () => {
  const context = useContext(UtxoContext);

  if (!context) {
    throw new Error(
      "useWalletContext must be used within a ThemecontextProvider"
    );
  }

  const {
    totalRunes,
    totalSats,
    btcUtxos,
    runeUtxos,
    setBtcUtxos,
    setRuneUtxos,
  } = context;

  return {
    totalRunes,
    totalSats,
    btcUtxos,
    runeUtxos,
    setBtcUtxos,
    setRuneUtxos,
  };
};
export default useUtxoContext;
