import { useContext } from "react";
import { SoulBoundContext } from "src/contexts/SoulBoundContext";

const useSoulboundContext = () => {
  const context = useContext(SoulBoundContext);

  if (!context) {
    throw new Error(
      "useSoulboundContext must be used within a ThemecontextProvider"
    );
  }

  const { order, orders, setOrder, setOrders, checkOrderStatus } = context;

  return {
    order,
    orders,
    setOrder,
    setOrders,
    checkOrderStatus,
  };
};
export default useSoulboundContext;
