import React from "react";
import clx from "classnames";
import _ from "lodash";
import styles from "./index.module.scss";
import HistoryTable from "../HistoryTable";

const TxHistory: React.FC = () => {
  return (
    <div className={styles.history_wrapper}>
      <div className={styles.history_header}>
        <span>Tx Hisotry</span>
      </div>
      <div className={clx(styles.history_content)}>
        <HistoryTable />
      </div>
    </div>
  );
};

export default TxHistory;
