import React from "react";
import _ from "lodash";
import styles from "./index.module.scss";
import useClaimContext from "src/hooks/useClaimContext";
import {
  convertDisplayDateTime,
  getReducedText,
  numberWithCommas,
} from "src/utils";
import { ClaimStatus } from "src/types/claim";
import { MEMPOOL_URL } from "src/config";

const HistoryTable: React.FC = () => {
  const { claims } = useClaimContext();

  const historyClaims = claims.filter(
    (_claim) => _claim.status !== ClaimStatus.DEFAULT
  );

  return (
    <>
      <table className={styles.historyTable}>
        <thead>
          <tr className={styles.historyTableHeaderRow}>
            <th className={styles.historyTableHeaderCell}>DATE</th>
            <th className={styles.historyTableHeaderCell}>AMOUNT</th>
            <th className={styles.historyTableHeaderCell}>TX</th>
            <th className={styles.historyTableHeaderCell}>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {historyClaims?.length > 0 &&
            historyClaims.map((_claim) => {
              return (
                <tr key={_claim._id} className={styles.historyTableRow}>
                  <td className={`${styles.historyTableCell} `}>
                    {convertDisplayDateTime(_claim.updatedAt)}
                  </td>
                  <td className={`${styles.historyTableCell} `}>
                    {numberWithCommas(_claim.amount)}
                  </td>
                  <td className={`${styles.historyTableCell} `}>
                    <a
                      href={`${MEMPOOL_URL}/tx/${_claim?.runeTxId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getReducedText(_claim.runeTxId)}
                    </a>
                  </td>
                  <td className={`${styles.historyTableCell} `}>
                    {_claim.status === ClaimStatus.CLAIMING ? (
                      <span style={{ color: "red" }}>PENDING</span>
                    ) : (
                      <span style={{ color: "green" }}> COMPLETED</span>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {_.isEmpty(historyClaims) && (
        <div className={styles.emptyData}>Empty Data</div>
      )}
    </>
  );
};

export default HistoryTable;
